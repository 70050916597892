import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { VscCheckAll } from "react-icons/vsc";
import { CgClose } from "react-icons/cg";
import Bannar from "../../assets/default_banner.jpg";
import Footer from "../Footer/Footer";
import StoreSkeleton from "./StoreSkeleton";
import "./Store.css";
import 'react-loading-skeleton/dist/skeleton.css'

const Store = () => {
  const [vendors, setVendors] = useState([]);
  const [showInquiryPopup, setShowInquiryPopup] = useState(false);
  const [productSuggestions, setProductSuggestions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const [showCategorySuggestions, setShowCategorySuggestions] = useState(true);
  const [, setShowCitySuggestions] = useState(true);
  const [, setIsSuggestionSelected] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedCitySuggestion, setSelectedCitySuggestion] = useState(-1);
  const [previousCitySuggestions, setPreviousCitySuggestions] = useState([]);
  const [number, setnumber] = useState(1);
  const [pages, setPages] = useState(1);
  const [, setCityInputClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [skeleton, setSkeleton] = useState(true);

  const productSuggestionsRef = useRef(null);
  const citySuggestionsRef = useRef(null);
  const storeRef = useRef(null);

  const fetchVendorDetails = async () => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom/v1/list-directory-listings?per_page=9&page=${number}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const vendorDetails = response.data.directory_listings;
      setVendors(vendorDetails);
      setSkeleton(false)
      setPages(response.data.total_pages);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };

  const isStoreOpen = (openingHours, vacationDates) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // Returns 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const currentTimeInSeconds = currentDate.getHours() * 3600 + currentDate.getMinutes() * 60 + currentDate.getSeconds();
  
    // Convert vacation dates from UNIX timestamp to Date objects
    const vacationDateObjects = vacationDates.map(timestamp => new Date(timestamp * 1000));
  
    // Check if today's date is in the vacation dates array
    const isTodayVacation = vacationDateObjects.some(date => date.toDateString() === currentDate.toDateString());
  
    // If today is a vacation day, store is closed
    if (isTodayVacation) {
      return false;
    }
  
    for (const hour of openingHours) {
      if (hour.day === currentDay) {
        if (currentTimeInSeconds >= hour.start && currentTimeInSeconds <= hour.end) {
          return true; // Store is open
        }
      }
    }
    return false; // Store is closed
  };
   

  const fetchVendorDetailsWithProductAndCity = async (product, city) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      let url;
      let vendorDetails = [];
      setVendors([])
      setSkeleton(true)
      if (city) {
        url = `https://shop.pricefinder.in/wp-json/custom-dir/v1/vendor-stores?tags[]=pro-${product}&tags[]=city-${city}`
      }
      else {
        url = `https://shop.pricefinder.in/wp-json/custom-dir/v1/vendor-stores?tags[]=pro-${product}`
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });
      vendorDetails = response.data.vendor_stores
      setVendors(vendorDetails)
      setSkeleton(false)
      setnumber(response.data.current_page)
      setPages(response.data.total_pages);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };

  const fetchProductSuggestions = async (inputText) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom/v1/directory-product-tag-suggestions?input=pro-${inputText}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const productDetails = response.data;
      setProductSuggestions(productDetails);
    } catch (error) {
      console.error("Error fetching product suggestions:", error);
    } finally {
      setShowCategorySuggestions(true);
    }
  };

  const fetchCitySuggestions = async (inputText) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom-dir/v1/combined-tag-suggestions?input=pro-${inputText}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const cityDetails = response.data;
      setCitySuggestions(cityDetails);
      setPreviousCitySuggestions(cityDetails);
      setSelectedCitySuggestion(-1);
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    } finally {
      setShowCitySuggestions(true);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    // Collect form data
    const formData = new FormData(event.target);

    // Create the jsonObject based on the desired format
    const jsonObject = {
      subject: `${formData.get("subject")}`,
      name: `${formData.get("name")}`,
      phone: `${formData.get("phone")}`,
      email: `${formData.get("email")}`,
      description: `${formData.get("description")}`,
    };
    fetch(
      "https://shop.pricefinder.in/wp-json/custom/v1/submit-freshdesk-ticket",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonObject),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.error(data.message);
        setLoading(false);
        setFormLoading(false);
        // setSelectedProduct(null);
        // setinquiryPopup(false);
        // alert("Thank you for your inquiry! We will get back to you soon.");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setLoading(false);
      });
  }

  const handleCitySuggestionNavigation = (e) => {
    const suggestions = citySuggestions;
    const lastIndex = suggestions.length - 1;

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setSelectedCitySuggestion((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : lastIndex
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedCitySuggestion((prevIndex) =>
          prevIndex < lastIndex ? prevIndex + 1 : 0
        );
        break;
      case "Enter":
        e.preventDefault();
        if (selectedCitySuggestion !== -1) {
          const selectedSuggestionText = suggestions[selectedCitySuggestion];
          handleCitySuggestionClick(selectedSuggestionText);
        }
        break;
      default:
        break;
    }
  };

  const handleProductSuggestionClick = (suggestion) => {
    setSearchText(suggestion);
    fetchVendorDetailsWithProductAndCity(suggestion);
    setIsSuggestionSelected(true);
    setShowCategorySuggestions(true);
    fetchCitySuggestions(suggestion);
    setCityInputClicked(false);
  };

  const handleCitySuggestionClick = (city) => {
    setSearchCity(city);
    fetchVendorDetailsWithProductAndCity(searchText, city);
    setIsSuggestionSelected(false);
    setShowCitySuggestions(false);
    setCityInputClicked(true);
    setCitySuggestions([]);
  };

  const handleInquiry = (vendor) => {
    setSelectedVendor(vendor);
    setShowInquiryPopup(true);
  };

  if (showInquiryPopup) {
    document.body.classList.add('active-enquiry')
  }
  else {
    document.body.classList.remove('active-enquiry')
  }

  const handleCloseClick = () => {
    setSelectedVendor(null);
    setShowInquiryPopup(false);
    setFormLoading(true);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("inquiry_overlay")) {
      handleCloseClick();
    }
  };

  useEffect(() => {
    fetchVendorDetails();
  }, []);

  useEffect(() => {
    // fetchVendorDetailsWithProductAndCity(searchText, searchCity);
    setSkeleton(true)
  }, []);

  useEffect(() => {
    fetchVendorDetails();
    setVendors([])
    setSkeleton(true)
  }, [number]);

  const handleOutsideClick = (event) => {
    if (
      // Check if the click happened outside the second input box
      (!citySuggestionsRef.current ||
        !citySuggestionsRef.current.contains(event.target)) &&
      // Check if the click happened outside the first input box
      (!productSuggestionsRef.current ||
        !productSuggestionsRef.current.contains(event.target)) &&
      // Check if the click happened outside the city input box
      (!document.getElementById("city-search-input") ||
        event.target !== document.getElementById("city-search-input"))
    ) {
      setCityInputClicked(false);
      setIsSuggestionSelected(false);
      setProductSuggestions([]);
      setCitySuggestions([]);
    }
  };

  const searchVendor = () => {
    if (searchText === "" && searchCity === "") {
      fetchVendorDetails();
    }
  };

  const handleNextPage = () => {
    setnumber((prevNumber) => prevNumber + 1);
    storeRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handlePreviousPage = (e) => {
    setnumber((prevNumber) => prevNumber - 1);
    storeRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div className="store_search_content" ref={storeRef}>
        <div className="store_search">
          <div className="store_search_category">
            <input
              type="search"
              placeholder="Search product..."
              value={searchText}
              onChange={(e) => {
                const inputText = e.target.value.toLowerCase();
                setSearchText(inputText);
                fetchProductSuggestions(inputText);
                setIsSuggestionSelected(false);
                setSearchCity("")
              }}
            />
            <ul
              className="suggestions"
              ref={productSuggestionsRef}
              style={{
                display:
                  searchText && showCategorySuggestions ? "block" : "none",
              }}
            >
              {productSuggestions.map((suggestion) => (
                <li
                  key={suggestion}
                  onClick={() => {
                    handleProductSuggestionClick(suggestion);
                    setShowCategorySuggestions(false);
                  }}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          </div>
          <div className="search_city">
            <input
              type="search"
              placeholder="Search city..."
              list="cityList"
              value={searchCity}
              id="city-search-input"
              onChange={(e) => {
                const inputText = e.target.value.toLowerCase();
                setSearchCity(inputText);
                const filteredSuggestions = previousCitySuggestions.filter(
                  (suggestion) => suggestion.toLowerCase().startsWith(inputText)
                );
                setCitySuggestions(filteredSuggestions);
                setIsSuggestionSelected(false);
              }}
              onClick={(e) => {
                const inputText = e.target.value.toLowerCase();
                setSearchCity(inputText);
                const filteredSuggestions = previousCitySuggestions.filter(
                  (suggestion) => suggestion.toLowerCase().startsWith(inputText)
                );
                if (searchText !== "") {
                  setCitySuggestions(previousCitySuggestions);
                }
                setIsSuggestionSelected(false);
              }}
              onKeyDown={handleCitySuggestionNavigation}
              disabled={previousCitySuggestions.length < 1}
            />
            <ul className="suggestions" ref={citySuggestionsRef}>
              {citySuggestions.map((suggestion, index) => {
                const isSelected = index === selectedCitySuggestion;
                return (
                  <li
                    onClick={() => handleCitySuggestionClick(suggestion)}
                    key={suggestion}
                    className={isSelected ? "selected" : ""}
                  >
                    {suggestion}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="store_search_button" onClick={searchVendor}>
            <span>
              <svg
                width="25"
                height="25"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"></path>
                <path d="m21 21-6-6"></path>
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div className="stores">
        <div className="store__list">
          {skeleton && <StoreSkeleton store={6} />}
          {vendors.map((vendor, index) => (
            <div className="store" key={vendor.author_id}>
              <div
                className="store_banner"
                style={{
                  backgroundImage: `url(${vendor.featured_image_url || Bannar
                    })`,
                }}
              >
                <div className="store_banner_content">
                  <div className="store_title">
                    {vendor.author_id ? (<Link
                      to={`/store/${vendor.author_id}`}
                      style={{ textDecoration: "none", color: "#000" }}
                    >{vendor.post_title ? (<span className="store__title">{vendor.post_title}</span>) : (<span className="store__title">{vendor.vendor_display_name}</span>)}
                    </Link>) : (<Link
                      to={`/store/${vendor.vendor_id}`}
                      style={{ textDecoration: "none", color: "#000" }}
                    >{vendor.post_title ? (<span className="store__title">{vendor.post_title}</span>) : (<span className="store__title">{vendor.vendor_display_name}</span>)}
                    </Link>)}{" "}
                    <span className="store_Status">
                      {(!vendor.post_shop_open && !vendor.post_shop_close) ? (
                        isStoreOpen(vendor.opening_hours, vendor.vacation_dates) ?
                          <span className="opened">Open</span> : <span className="closed">Closed</span>
                      ) : (
                        vendor.post_shop_open ?
                          <span className="opened">Open</span> : <span className="closed">Closed</span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="store_visit">
                <span onClick={() => handleInquiry(vendor)}>
                  <div className="inquiry_btn">
                    <AiOutlineQuestionCircle />
                    Inquiry
                  </div>
                </span>
                {/* {vendor.slug && (
                  <Link to={`https://shop.pricefinder.in/directory/listing/${vendor.slug}`} target="_blank">
                    <div className="visit">VISIT</div>
                  </Link>
                )} */}

                {vendor.author_id ? (
                  <Link to={`/store/${vendor.author_id}`}>
                    <div className="visit">VISIT</div>
                  </Link>
                ) : (
                  <Link to={`/store/${vendor.vendor_id}`}>
                    <div className="visit">VISIT</div>
                  </Link>
                )}
              </div>
              {vendor.vendor_logo && <div className="store_logo">
                <img src={vendor.vendor_logo} alt="logo" />
              </div>}
            </div>
          ))}
          {showInquiryPopup && selectedVendor && (
            <div className="inquiry_overlay" onClick={handleOverlayClick}>
              <div className="inquiry_popup">
                <div className="popup__header">
                  <div className="popup__product">
                    <h3>{selectedVendor.author_name}</h3>
                  </div>
                  <button className="close_button" onClick={handleCloseClick}>
                    <CgClose />
                  </button>
                  {selectedVendor.featured_image_url !== "" && <div className="product__logo">
                    <img src={selectedVendor.featured_image_url} alt="" />
                  </div>}

                </div>

                {formLoading && (
                  <div className="heading_close">
                    <div className="heading_popup">
                      <h4>Ask a Question</h4>
                    </div>
                  </div>
                )}
                {formLoading ? (
                  <form onSubmit={handleSubmit} method="POST" className="form">
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name (required)"
                      required
                      autoFocus
                    />
                    <input
                      type="hidden"
                      name="subject"
                      value={
                        selectedVendor.author_name
                      }
                    />
                    <div className="input__email">
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email (required)"
                        inputMode="email"
                        required
                      />
                      <input
                        type="phone"
                        name="phone"
                        placeholder="Phone Number (required)"
                        required
                        inputMode="tel"
                      />
                    </div>
                    <textarea
                      type="text"
                      className="textarea"
                      name="description"
                      required
                      placeholder="Your Message (required)"
                    ></textarea>
                    <div className="h-captcha" data-captcha="true"></div>
                    <button type="submit" disabled={loading}>
                      {loading ? "SENDING..." : "SEND"}
                    </button>
                  </form>
                ) : (
                  <div className="thankyou__message">
                    <div className="icon">
                      <VscCheckAll />
                    </div>
                    <p>
                      Thank you for your inquiry! We will get back to you soon.
                    </p>
                    <button
                      className="btn btn-success center"
                      onClick={handleCloseClick}
                    >
                      Close
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="navigation_button">
          <div className="page_nav">
            {number > 1 && pages > 1 ? (
              <button
                className="btn btn-info button_previous"
                onClick={handlePreviousPage}
              >
                Previous
              </button>
            ) : (
              <button className="btn btn-info button_previous disabled">
                Previous
              </button>
            )}
            <span className="page__number">
              {number} of {pages}
            </span>
            {pages > number ? (
              <button
                className="btn btn-info button_next"
                onClick={handleNextPage}
              >
                Next
              </button>
            ) : (
              <button className="btn btn-info button_next disabled">
                Next
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Store;
