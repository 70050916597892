import React from 'react'
import Footer from '../Footer/Footer'

const Reviews = () => {
  return (
    <div>
      <h2 className='section_title'>Reviews</h2>
        <Footer />
    </div>
  )
}

export default Reviews