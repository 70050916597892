import React, { useState, useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { BiArrowBack } from "react-icons/bi";
import defaultImage from '../../assets/default.png'
import axios from 'axios';
import { VscCheckAll } from "react-icons/vsc";
import SpinnerLoader from "./SpinnerLoader";


const CartWindow = ({
  isOpen,
  onClose,
  totalPrice,
  cartItems,
  productQuantity,
  decreaseQuantity,
  increaseQuantity,
  clearCart,
}) => {
  const [inquiryPopup, setInquiryPopup] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [cartKeys, setCartKeys] = useState([]);
  const [overlayLoading, setOverlayLoading] = useState(false)


  useEffect(() => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item.price * (productQuantity[item.product_id] || 0);
    });
  }, [cartItems, productQuantity]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest(".cart-popup-content")) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
      document.body.classList.add('active-cart')

    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.body.classList.remove('active-cart');
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleInquiry = () => {
    setInquiryPopup(true);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const itemsArray = cartItems.map((item, index) => ({
      serial_number: index + 1,
      product_name: item.product_title,
      price: item.regular_price === "0" ? "RFQ" : item.regular_price,
      quantity: productQuantity[item.product_id],
      total:
        item.regular_price === "0"
          ? "-"
          : productQuantity[item.product_id] * item.regular_price,
    }));

    const formData = new FormData(event.target);
    const dataToSend = {
      name: `${formData.get("name")}`,
      phone: `${formData.get("phone")}`,
      email: `${formData.get("email")}`,
      shipping_address: `${formData.get("address")}`,
      vendor_name: `${cartItems[0].author_name}`,
      order_details: {
        items: itemsArray,
        total: `₹${totalPrice.toFixed(2)}`,
      },
      subject: `${cartItems[0].author_name} - Orders`,
    };


    try {
      const response = await fetch(
        "https://shop.pricefinder.in/wp-json/custom/v1/submit-product-enquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.error(responseData.message);
        setLoading(false);
        setFormLoading(false);
        clearCart();
        onClose();
      } else {
        console.error("Failed to send cartItems");
      }
    } catch (error) {
      console.error("Error sending cartItems:", error);
      setLoading(false);
    }
  };


  if (inquiryPopup) {
    document.body.classList.add('active-enquiry')
  }
  else {
    document.body.classList.remove('active-enquiry')
  }



  const handlePlaceOrder = async (event) => {
    event.preventDefault();
    setOverlayLoading(true)
    const items = cartItems.map(item => ({
      id: String(item.product_id),
      quantity: String(productQuantity[item.product_id] || 0)
    }));

    const dataToSend = { items }

    try {
      // Make a request to your custom add-to-cart endpoint
      const response = await axios.post(
        "https://shop.pricefinder.in/wp-json/custom/v1/add-to-cart",
        dataToSend,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      const newCartKeys = response.data[0];

      setCartKeys(newCartKeys);

      const redirectUrl = `https://shop.pricefinder.in/shopping-cart/?cocart-load-cart=${newCartKeys}`;

      window.open(redirectUrl, '_blank');
      onClose();

    } catch (error) {
      setOverlayLoading(false)
      console.error('Error during checkout:', error);
    }
    finally {
      setOverlayLoading(false);
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("inquiry_overlay")) {
      handleCloseClick();
    }
  };

  const handleCloseClick = () => {
    setInquiryPopup(false);
    setFormLoading(true);
  };

  return (
    <>
      <div className={`cart-backdrop ${isOpen ? "open" : ""}`}></div>
      <div className={`cart-popup ${isOpen ? "open" : ""}`}>
        <div className="cart-popup-content">
          <div className="popup__header">
            <h4>YOUR CART</h4>
            <span onClick={onClose}>
              <CgClose />
            </span>
          </div>
          <span className="countinue__shoping" onClick={onClose}>
            <span>
              <BiArrowBack />
              Countinue Shopping
            </span>
          </span>
          <hr />

          {cartItems.length > 0 ? (
            <div className="cart_items">
              {cartItems.map((value) => (
                <>
                  {value &&
                    <div className="cart__product" key={value.product_id}>
                      <div className="cart__image">
                        {value.featured_image_url ? (
                          <img
                            src={value.featured_image_url}
                            className="card-img-top product-image"
                            alt={value.product_title}
                          />
                        ) : (<img
                          src={defaultImage}
                          className="card-img-top product-image"
                          alt={value.product_title}
                        />)}
                      </div>
                      <div className="cart__productdetails">
                      <h6 dangerouslySetInnerHTML={{ __html: value.product_title }}></h6>
                        {value.regular_price === "0" ? <p>RFQ</p> : <p>₹{Number(value.regular_price).toFixed(2)}</p>}
                        <div className="plus__minus">
                          <div
                            className={`total_add ${productQuantity[value.product_id] > 0 ? "active" : ""
                              }`}
                          >
                            <div
                              className="minus_cart"
                              onClick={() => decreaseQuantity(value.product_id)}
                            >
                              -
                            </div>
                            {productQuantity[value.product_id] > 0 && (
                              <div className="item_count_cart">
                                {productQuantity[value.product_id]}
                              </div>
                            )}
                            <div
                              className="plus_cart"
                              onClick={() => increaseQuantity(value.product_id)}
                            >
                              +
                            </div>
                          </div>
                          {value.regular_price !== "0" &&
                            <p className="subtotal">
                              {productQuantity[value.product_id]} * ₹{value.regular_price} = ₹{productQuantity[value.product_id] * value.regular_price}
                            </p>
                          }

                        </div>
                      </div>
                    </div>
                  }
                  <hr />
                </>
              ))}
              <div className="cart_footer">
                <div className="total_price">
                  <span>Total:</span>
                  <span className="price">₹{totalPrice.toFixed(2)}</span>
                </div>

                <div className="total_cart_price">
                  <div className="total_cart_checkout">
                    <span onClick={handlePlaceOrder} className="place_order_btn">Place Order</span>
                    <span onClick={handleInquiry} className="inquiry_btn">Inquiry</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h2 className="cart__empty">Your cart is currently</h2>
              <h2 className="cart__emptyemoji">empty!!</h2>
            </div>
          )}
        </div>
      </div>
      {inquiryPopup && (
        <div className="inquiry_overlay" onClick={handleOverlayClick}>
          <div className="inquiry_popup order_inquiry">
            <div className="popup__header">
              <button className="close_button" onClick={handleCloseClick}>
                <CgClose />
              </button>
              <div className="product__logo">
                {/* <img src={selectedProduct.image} alt="" /> */}
              </div>
            </div>

            {formLoading && (
              <div className="heading_close">
                <div className="heading_popup">
                  <h4>Enter the Details</h4>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {inquiryPopup && (
        <div className="inquiry_overlay" onClick={handleOverlayClick}>
          <div className="inquiry_popup order_inquiry">
            <div className="popup__header">
              <button className="close_button" onClick={handleCloseClick}>
                <CgClose />
              </button>
              <div className="product__logo">
                {/* <img src={selectedProduct.image} alt="" /> */}
              </div>
            </div>

            {formLoading && (
              <div className="heading_close">
                <div className="heading_popup">
                  <h4>Enter the Details</h4>
                </div>
              </div>
            )}

            {formLoading ? (
              <form onSubmit={handleSubmit} method="POST" className="form form_address">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name (required)"
                  required
                  autoFocus
                />
                <div className="input__email">
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email (required)"
                    inputMode="email"
                    required
                  />
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Phone Number (required)"
                    inputMode="tel"
                    required
                  />
                </div>
                <textarea
                  type="text"
                  className="shipping_address_textarea"
                  name="address"
                  required
                  placeholder="Your Shipping Address (required)"
                ></textarea>
                <div className="h-captcha" data-captcha="true"></div>
                <button type="submit" disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </form>
            ) : (
              <div className="thankyou__message">
                <div className="icon">
                  <VscCheckAll />
                </div>
                <p>
                  Thank you for your order! We'll be in touch shortly.
                </p>
                <button
                  className="btn btn-success center"
                  onClick={handleCloseClick}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {overlayLoading && (
        <div className="loading-overlay">
          <SpinnerLoader visible={overlayLoading} />
        </div>
      )}

    </>
  );
};

export default CartWindow;
