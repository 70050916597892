const data = [
    {
        id: 1,
        'title': "24/7 Support",
        "des": "We offer around-the-clock support through both phone and ticket channels. Our dedicated phone lines and call center are staffed with knowledgeable professionals who are ready to assist our customers at any time. Whether you have urgent queries or need help troubleshooting an issue, our support team is here to provide timely assistance.",
        "img": "https://img.freepik.com/free-photo/confident-call-center-operator-talking-with-client_74855-4059.jpg?w=740&t=st=1705730322~exp=1705730922~hmac=e051b09981950273d0f6059a6a84732849ae7940f8a90c78b29bc91aae7baa09"
    },
    {
        id: 2,
        'title': "Reliable",
        "des": "Ensuring a seamless experience for our clients, we manage client inquiries through an efficient ticket management system. Each customer is provided with a personalized customer portal where they can conveniently access and manage their historical inquiries. This commitment to reliability extends to our systems and processes, ensuring that our clients can trust us with their needs.",
        "img": "https://img.freepik.com/free-photo/handshake-businessmen_1098-742.jpg?w=740&t=st=1705900637~exp=1705901237~hmac=26b5131b18c049db411c3a3187971c9b45ce800e2705f9c60335e573b386c915"
    },
    {
        id: 3,
        'title': "Tech-First",
        "des": "At the core of our philosophy is a belief in the power of technology to deliver the best possible experience for our customers. We leverage cutting-edge technology to enhance our services, streamline processes, and stay at the forefront of innovation. By prioritizing a tech-first approach, we aim to provide our customers with solutions that are not only effective but also aligned with the latest advancements in the industry.",
        "img": "https://img.freepik.com/free-photo/rpa-concept-with-blurry-hand-touching-screen_23-2149311914.jpg?w=740&t=st=1705900747~exp=1705901347~hmac=3d98e5b2af940547b3d548fadc8a9da40e1587afaeb3cf57a866ef7f610fa910"
    }
]

export default data;