import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FaLocationDot, FaMapLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import Products from './Products'
import About from "./About";
import Contact from "./Contact";
import Reviews from "./Reviews";
import Offer from "./Offer";
import "./Vendor.css";
import "../CartWindow/CartWindow.css";
import Gallery from "./Gallery";

const Vendor = () => {
  const [showvendor, setShowVendor] = useState([]);
  const [activeSection, setActiveSection] = useState("Products");
  const [productsAvailable, setProductsAvailable] = useState(false);
  const [directions, setDirections] = useState("")
  const [storeStatus, setStoreStatus] = useState("")
  const [toggle, setToggle] = useState(null)
  const [vacation, setVacation] = useState("")
  const [isVacationChecked, setIsVacationChecked] = useState(false);
  const [images, setImages] = useState([])

  const { vendorId } = useParams();

  const productShowRef = useRef(null);

  const fetchVendorDetails = async (vendorId) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      const url = `https://shop.pricefinder.in//wp-json/custom/v1/get-directory-details-by-author/${vendorId}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });
      const vendorDetails = response.data;
      setShowVendor(vendorDetails);
      setDirections(vendorDetails.directory_directions);
      setToggle(vendorDetails.directory_table_layout)
      setImages(vendorDetails.directory_photos_urls)
      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const year = today.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;

      if (vendorDetails.directory_vacation && vendorDetails.directory_vacation.includes(formattedDate)) {
        setVacation("closed");
      }

      setIsVacationChecked(true); // Set vacation check completion flag

    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    }
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
    if (productShowRef.current) {
      productShowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const storeOpenCloseImp = async () => {
    if (storeStatus === "closed") {
      return;
    }
    const dataToSend = {
      author_id: vendorId,
    };
    try {
      const response = await axios.post(
        "https://shop.pricefinder.in/wp-json/custom/v1/update-store-status",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setStoreStatus(response.data.store_status);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const currentDay = (new Date().getDay() + 6) % 7;

  useEffect(() => {
    fetchVendorDetails(vendorId);
  }, []);

  useEffect(() => {
    if (isVacationChecked) {
      storeOpenCloseImp();
    }
  }, [isVacationChecked]);


  return (
    <>
      <div className="vendor">
        <div className="vendorTop">
          <div className="vendorTitleContainer">
            <h1 className="vendorTitle">
              {showvendor.post_title}{" "}
              {storeStatus && <span className={`${(storeStatus === "closed" || vacation === "closed") ? 'closed' : 'opened'} status`}>
                {(storeStatus === "closed" || vacation === "closed") ? "Closed" : "Open Now"}
              </span>}
            </h1>
          </div>
          <div className="vendorInfo">
            <div className="contactInfo">
              {showvendor.directory_location && <div>
                <h2 className="vendorSubtitle">Contact Information</h2>
                <div className="contactDetails">
                  <div className="vendorLocation contactRow">

                    {showvendor.directory_location && showvendor.directory_location.display_address &&
                      <><span><FaLocationDot /></span><span>{showvendor.directory_location.display_address}</span></>
                    }
                  </div>
                  <div className="vendorPhone contactRow">
                    {showvendor.directory_phone &&
                      <><span><FaPhoneAlt /></span><span>{showvendor.directory_phone}</span></>
                    }
                  </div>
                  <div className="vendorMap contactRow">
                    {showvendor.directory_directions &&
                      <>
                        <span><FaMapLocationDot /></span>
                        <a href={directions} target="_blank">
                          <span>Get Directions</span>
                        </a>
                      </>
                    }
                  </div>
                </div>
              </div>}
              {showvendor.directory_vacation && showvendor.directory_vacation.length > 0 && (
                <div>
                  <h2 className="vendorSubtitle vacation">Vacation Dates</h2>
                  {showvendor.directory_vacation.map((date, index) => (
                    <span key={index}>
                      {date}
                      {index !== showvendor.directory_vacation.length - 1 && ", "}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <div className="openingHours">
              {showvendor.opening_hours_formatted &&
                <div>
                  <h2 className="vendorSubtitle">Opening Hours</h2>
                  {storeStatus && (
                    <p className="storeStatus">
                      {(vacation === 'closed' || (showvendor.directory_shop_close && !showvendor.directory_shop_open)) && <span>The store was intentionally closed by the author</span>}
                      {( vacation !== 'closed' && showvendor.directory_shop_open && !showvendor.directory_shop_close) && <span>The store was intentionally opened by the author</span>}
                    </p>
                  )}
                  <div className="vendorHours">
                    {showvendor && showvendor.opening_hours_formatted &&
                      showvendor.opening_hours_formatted.split('\n')
                        .filter(line => line.trim() !== '')
                        .map((line, index) => (
                          <p key={index} className="vendorHoursRow" style={{ fontWeight: index === currentDay ? 'bold' : 'normal' }}>
                            <MdOutlineAccessTimeFilled /> {" "}
                            <span>{line}</span>
                          </p>
                        ))
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="vendor_nav">
        <div className="section_nav">
          {productsAvailable && (<>
            <span
              onClick={() => handleSectionClick("Products")}
              className={activeSection === "Products" ? "active" : ""}
            >
              Shop
            </span>
            <span
              onClick={() => handleSectionClick("Offers")}
              className={activeSection === "Offers" ? "active" : ""}
            >
              Offer
            </span>
          </>)}
          <span
            onClick={() => handleSectionClick("Gallery")}
            className={activeSection === "Gallery" ? "active" : ""}
          >
            Gallery
          </span>
          <span
            onClick={() => handleSectionClick("About")}
            className={activeSection === "About" ? "active" : ""}
          >
            About
          </span>
          <span
            onClick={() => handleSectionClick("Contact")}
            className={activeSection === "Contact" ? "active" : ""}
          >
            Contact
          </span>
        </div>
      </div>
      <div>
        {activeSection === "Products" && <Products toggle={toggle} isVacation={vacation} storeStatus={storeStatus} setActiveSection={setActiveSection} setProductsAvailable={setProductsAvailable} />}
        {activeSection === "Offers" && (
          <div className="offer-section">
            <Offer id={vendorId} />
          </div>
        )}
        {activeSection === "About" && (
          <div className="about-section">
            <About about={showvendor} />
          </div>
        )}
        {activeSection === "Gallery" && (
          <div className="gallery-section">
            <Gallery images={images} />
          </div>
        )}
        {activeSection === "Contact" && (
          <div className="contact-section">
            <Contact />
          </div>
        )}
        {activeSection === "Reviews" && (
          <div className="reviews-section">
            <Reviews />
          </div>
        )}
      </div>
    </>
  );
};

export default Vendor;
