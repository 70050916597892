import React, { useState, useEffect } from 'react';
import { RotatingLines } from "react-loader-spinner";

const SpinnerLoader = ({ visible }) => {
    const [showSpinner, setShowSpinner] = useState(visible);

    useEffect(() => {
        let timer;
        if (visible) {
            setShowSpinner(true);
            timer = setTimeout(() => {
                setShowSpinner(false);
            }, 3000); 
        } else {
            setShowSpinner(false);
        }

        return () => clearTimeout(timer);
        
    }, [visible]);

    return (
        <div style={{
            display: showSpinner ? 'flex' : 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 9999 
        }}>
            <RotatingLines
                height="96"
                width="96"
                color="grey"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
            <h3>We are processing your request, please wait...</h3>
        </div>
    );
}

export default SpinnerLoader;
