import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import defaultImage from '../../assets/default.png'
import CartWindow from "../CartWindow/CartWindow";
import Footer from "../Footer/Footer";
import Cart from "../Cart/Cart";
import "../CartWindow/CartWindow.css";
import '../HomePage/HomePage.css'
import "./Vendor.css";

const Products = (props) => {
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [productSuggestionShow, setProductSuggestionShow] = useState([]);
  const [selectedProductSuggestion, setSelectedProductSuggestion] =
    useState(-1);
  const [, setIsSuggestionSelected] = useState(false);
  const [, setFilteredProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [data, setData] = useState("");
  const [, setShowProducts] = useState([]);
  const [productQuantity, setProductQuantity] = useState({});
  const [itemCount, setItemCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [number, setnumber] = useState(1);
  const [pages, setPages] = useState(1);
  const [isCartPopupOpen, setIsCartPopupOpen] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [showCategoryList, setShowCategoryList] = useState(true);
  const [isHyperLocal, setIsHyperLocal] = useState(false)
  const [isVirtual, setIsVirtual] = useState(false);

  const { toggle, isVacation, storeStatus, setActiveSection, setProductsAvailable } = props;

  const productShowRef = useRef(null);
  const productSuggestionRef = useRef(null);

  // const vendorId = props.id;
  const { vendorId } = useParams();
  // const  = props.toggle;

  const fetchcategory = async (vendorId) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      const url = `https://shop.pricefinder.in/wp-json/custom/v1/author-categories/${vendorId}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });
      const categoryDetails = response.data.categories;

      categoryDetails.all = {
        id: "all",
        name: "All",
        children: [],
      };

      // Define the order of days of the week
      const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Uncategorized"];

      // Convert the object's values into an array
      const categoriesArray = Object.values(categoryDetails);

      // Sort the categories array based on the order of days of the week
      categoriesArray.sort((a, b) => {
        const indexA = daysOrder.indexOf(a.name);
        const indexB = daysOrder.indexOf(b.name);
        return indexA - indexB;
      });

      // Move "All" category to the beginning of the array
      const allIndex = categoriesArray.findIndex(category => category.name === "All");
      if (allIndex !== -1) {
        const allCategory = categoriesArray.splice(allIndex, 1)[0];
        categoriesArray.unshift(allCategory);
      }

      setCategory(categoriesArray);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  function toggleCategoryExpansion(categoryId) {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  }

  const handleCategoryChange = (categoryId) => {
    if (window.innerWidth < 768)
      setShowCategoryList(false);
    else setShowCategoryList(true);
    setData(categoryId);
    setnumber(1);
    if (productShowRef.current) {
      productShowRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const fetchproducts = async () => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      let url;
      if (data === "all") {
        url = `https://shop.pricefinder.in/wp-json/custom/v1/list-products/${vendorId}?page=${number}&per_page=16`;
      } else if (data !== "" && data !== "all") {
        url = `https://shop.pricefinder.in//wp-json/custom/v1/get-products-by-author-and-category/${vendorId}/${data}?page=${number}&per_page=16`;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });
      const productDetails = response.data.products;
      setIsHyperLocal(productDetails[0].hyperlocal)
      setIsVirtual(productDetails[0].is_virtual)
      if (productDetails.length > 0) {
        setProductsAvailable(true);
      }
      else {
        setActiveSection("Gallery")
      }
      const currentDate = new Date();
      let currentDay = currentDate.getDay();
      currentDay = currentDay === 0 ? 7 : currentDay;  // Because our days starts from 1 to 7
      const currentHour = currentDate.getHours();
      const filteredProducts = productDetails.filter(product => {
        if (product.is_virtual) {
          if (product.disable_pro !== "1") {
            const productDay = parseInt(product.day_count);
            if (productDay === currentDay) {
              product.product_title = `<strong>Today</strong> - ${product.product_title}`
            }
            else if (productDay === currentDay + 1) {
              product.product_title = `<strong>Tomorrow</strong> - ${product.product_title}`
            }
            if (productDay === currentDay) {
              if (product.time_slot === 'morning' && currentHour >= 13) {
                return false;
              }
              return true;
            } else if (productDay >= currentDay || isNaN(productDay)) {
              return true;
            }

            return false;
          }
        } else {

          return true;
        }
      });

      setProducts(filteredProducts);
      setShowProducts(filteredProducts);
      setPages(response.data.total_pages);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };


  const handleCartDesignClick = () => {
    setIsCartPopupOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleCloseCartPopup = () => {
    setIsCartPopupOpen(false);
  };

  const handleNextPage = () => {
    setnumber(number + 1);
    if (productShowRef.current) {
      productShowRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handlePreviousPage = (e) => {
    setnumber(number - 1);
    productShowRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const increaseQuantity = (productId) => {
    setProductQuantity((prevQuantity) => {
      const updatedQuantity = { ...prevQuantity };
      updatedQuantity[productId] = (prevQuantity[productId] || 0) + 1;
      if (!prevQuantity[productId]) {
        setItemCount((prevCount) => prevCount + 1);
      }
      const product = products.find((item) => item.product_id === productId);
      if (product) {
        setTotalPrice((prevPrice) => prevPrice + Number(product.regular_price));
        // Add the product to the cartItems array if it's not already there
        if (!cartItems.find((item) => item.product_id === productId)) {
          setCartItems((prevCartItems) => [...prevCartItems, product]);
        }
      }
      return updatedQuantity;
    });
  };

  const decreaseQuantity = (productId) => {
    setProductQuantity((prevQuantity) => {
      if (prevQuantity[productId] && prevQuantity[productId] > 0) {
        const updatedQuantity = { ...prevQuantity };
        updatedQuantity[productId] = prevQuantity[productId] - 1;
        const product = products.find((item) => item.product_id === productId);
        if (product) {
          setTotalPrice((prevPrice) => prevPrice - product.regular_price);
          if (prevQuantity[productId] === 1) {
            setItemCount((prevCount) => prevCount - 1);
            setCartItems((prevCartItems) =>
              prevCartItems.filter((item) => item.product_id !== productId)
            );
          }
        }
        return updatedQuantity;
      }
      return prevQuantity;
    });
  };

  const clearCart = () => {
    setCartItems([]);
    setProductQuantity({});
    setItemCount(0);
    setTotalPrice(0);
  };

  const fetchProductSuggestions = async (inputText) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      const url = `https://shop.pricefinder.in/wp-json/custom/v1/product-tag-suggestions/${vendorId}?input=pro-${inputText}&page=1&per_page=10`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const productDetails = response.data;
      setProductSuggestionShow(productDetails);
      setSelectedProductSuggestion(-1);
    } catch (error) {
      console.error("Error fetching product suggestions:", error);
    } finally {
    }
  };

  const handleProductSuggestionClick = (sug) => {
    setSearchQuery(sug);
    fetchProductDetails(sug);
    setIsSuggestionSelected(true);
    setnumber(1);
    setProductSuggestionShow([]);
    setSelectedProductSuggestion(-1);
  };

  const fetchProductDetails = async (sug, pageNumber = 1) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom/v1/list-products-search-tags/${vendorId}/?tag_name=pro-${sug}&page=${pageNumber}&per_page=10`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const productDetails = response.data.products;
      if (response.data.total_pages === 0) {
        setPages(1);
      } else {
        setPages(response.data.total_pages);
      }
      setProducts(productDetails);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };


  const handleProductSuggestionNavigation = (e) => {
    const suggestions = productSuggestionShow;
    const lastIndex = suggestions.length - 1;

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setSelectedProductSuggestion((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : lastIndex
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedProductSuggestion((prevIndex) =>
          prevIndex < lastIndex ? prevIndex + 1 : 0
        );
        break;
      case "Enter":
        e.preventDefault();
        if (selectedProductSuggestion !== -1) {
          const selectedSuggestionText = suggestions[selectedProductSuggestion];
          handleProductSuggestionClick(selectedSuggestionText);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchcategory(vendorId);
  }, [vendorId]);

  useEffect(() => {
    fetchproducts();
  }, [data, number]);

  useEffect(() => {
    setFilteredProducts(products.filter(product =>
      product.product_title.toLowerCase().includes(searchQuery.toLowerCase())
    ));
  }, [searchQuery, products]);

  useLayoutEffect(() => {

    setData("all")
    const updateWidth = () => {
      if (window.innerWidth < 768)
        setShowCategoryList(false);
      else setShowCategoryList(true);
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);


  const toggleCategoryList = () => {
    setShowCategoryList((preshowCategoryList) => !preshowCategoryList)

  };

  return (
    <div className="products" ref={productShowRef}>
      <div className="category__products">
        <div className="category category-container">
          <div className="container-fluid mt-2">
            <div className="row">
              <div className="col-md-2 buttonsmenu all_filter_sticky  mt-2">
                {category.length > 1 &&
                  <div className="category__list">
                    <div className="category_line" onClick={toggleCategoryList}>Show Category
                      <span className="arrow">
                        {showCategoryList ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      </span>
                    </div>
                    {showCategoryList && Object.values(category).map((cate) => (
                      <div className={`row`} key={cate.id}>
                        <div className={`col-12 mt-2 btns category__name`}>
                          <div className="category">
                            <span className={`category_list ${cate.id === data ? "active" : ""}`}
                              onClick={() => {
                                handleCategoryChange(cate.id);
                                // setData(cate.id);
                                if (productShowRef.current) {
                                  productShowRef.current.scrollIntoView({
                                    behavior: "smooth",
                                    block: "start",
                                  });
                                }
                              }}
                            >{cate.name}</span>
                            {Object.keys(cate.children).length > 0 && (
                              <>
                                {expandedCategories[cate.id] ? (
                                  <span className="arrowup" onClick={() => toggleCategoryExpansion(cate.id)}>
                                    <IoIosArrowUp />
                                  </span>
                                ) : (
                                  <span className="arrowdown" onClick={() => toggleCategoryExpansion(cate.id)}>
                                    <IoIosArrowDown />
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {expandedCategories[cate.id] && Object.keys(cate.children).length > 0 && (
                          <div className="">
                            {Object.entries(cate.children).map(([childId, childName]) => (
                              <div className={`child_category ${childId === data ? "active" : ""}`} key={childId} onClick={() => {
                                handleCategoryChange(childId);
                                // setData(childId);
                                if (productShowRef.current) {
                                  productShowRef.current.scrollIntoView({
                                    behavior: "smooth",
                                    block: "start",
                                  });
                                }
                              }}>{childName}</div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>}
              </div>
              <div className="col-md-10 products">
                {!isVirtual &&
                  <div className="search_section">
                    <div className="search_product">
                      <input
                        type="search"
                        placeholder="Search Product..."
                        list="productList"
                        value={searchQuery}
                        id="search-input"
                        autoComplete='off'
                        onChange={(e) => {
                          const inputText = e.target.value.toLowerCase();
                          setSearchQuery(inputText);
                          fetchProductSuggestions(inputText);
                          setIsSuggestionSelected(false);
                        }}
                        onKeyDown={handleProductSuggestionNavigation}
                      />
                      <ul className="suggestions" ref={productSuggestionRef}>
                        {productSuggestionShow.slice(0, 10).map((sug, index) => {
                          const isSelected = index === selectedProductSuggestion;
                          return (
                            <li
                              onClick={() => handleProductSuggestionClick(sug)}
                              key={sug}
                              className={isSelected ? "selected" : ""}
                            >
                              {sug}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                }
                {toggle ?
                  <div>
                    {((storeStatus === "closed" || isVacation === "closed") && !isHyperLocal && products.length > 0) && <h5 className="message">We are currently unavailable but will still take your order. Delivery will be made once we are available. Thank you!</h5>}
                    {((storeStatus === "closed" || isVacation === "closed") && isHyperLocal && products.length > 0) && <h5 className="message">We're currently unavailable, so orders are temporarily paused. Please check back later. Thank you!</h5>}
                    {category.length > 1 && (
                      <Table bordered hover responsive>
                        <thead>
                          <tr style={{ textAlign: "center" }}>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.map((values, index) => (
                            <tr key={values.id}>
                              <td style={{ textAlign: "center" }} className="img_container">
                                {values.featured_image_url ? (
                                  <img
                                    src={values.featured_image_url}
                                    className="product-image product-image_table"
                                    alt={values.name}
                                    style={{ maxWidth: "100px" }}
                                  />
                                ) : (
                                  <img
                                    src={defaultImage}
                                    className="product-image product-image_table"
                                    alt={values.name}
                                    style={{ maxWidth: "100px" }}
                                  />
                                )}
                                <span className="img_enlarged">
                                  {values.featured_image_url ? (
                                    <img
                                      src={values.featured_image_url}
                                      alt={values.name}
                                    />
                                  ) : (
                                    <img
                                      src={defaultImage}
                                      alt={values.name}
                                    />
                                  )}
                                </span>
                              </td>
                              <td>
                                {values.product_slug ? (<Link to={`https://shop.pricefinder.in/product/${values.slug}`} target="_blank"><span dangerouslySetInnerHTML={{ __html: values.product_title }}></span></Link>) : (<span dangerouslySetInnerHTML={{ __html: values.product_title }}></span>)}
                                {" "}
                                {(values.is_virtual && values.product_stock_quantity !== null) &&
                                  <span>
                                    - Available {values.product_stock_quantity}
                                  </span>
                                }
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {values.regular_price !== "0" ? (
                                  <span>₹{Number(values.regular_price).toFixed(2)}</span>
                                ) : (
                                  <p>RFQ</p>
                                )}
                              </td>
                              <td>
                                {(((isVacation === "closed" || storeStatus === "closed") && !values.hyperlocal) || (isVacation !== "closed" && storeStatus !== "closed")) ? (
                                  values.product_out_of_stock ?
                                    <div className="outofstock">Out of Stock</div> :
                                    <div
                                      className={`addtocard ${productQuantity[values.product_id] > 0 ? "active" : ""
                                        }`}
                                    >
                                      {productQuantity[values.product_id] > 0 && (
                                        <div
                                          className="sub-btn"
                                          onClick={() => decreaseQuantity(values.product_id)}
                                        >
                                          -
                                        </div>
                                      )}
                                      {productQuantity[values.product_id] > 0 && (
                                        <div className="quantity-btn">
                                          {productQuantity[values.product_id]}
                                        </div>
                                      )}
                                      <div
                                        className="cartadd"
                                        onClick={() => increaseQuantity(values.product_id)}
                                        style={{
                                          display:
                                            productQuantity[values.product_id] > 0
                                              ? "none"
                                              : "block",
                                        }}
                                      >
                                        Add
                                      </div>
                                      <div
                                        className="add-sym"
                                        onClick={() => increaseQuantity(values.product_id)}
                                      >
                                        +
                                      </div>
                                    </div>
                                ) : (<div className="outofstock">Not Avaiable</div>)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div> :
                  <div>
                    {category.length > 1 && (
                      <div className="row mt-2">
                        {((storeStatus === "closed" || isVacation === "closed") && !isHyperLocal && products.length > 0) && <h5 className="message">We are currently unavailable but will still take your order. Delivery will be made once we are available. Thank you!</h5>}
                        {((storeStatus === "closed" || isVacation === "closed") && isHyperLocal && products.length > 0) && <h5 className="message">We're currently unavailable, so orders are temporarily paused. Please check back later. Thank you!</h5>}
                        {products.length > 0 ? <>{products.map((values) => (
                          <div
                            key={values.id}
                            className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: "space-between" }}
                          >
                            <div className="card" style={{ flex: '1' }}>
                              {values.product_slug ? (
                                <Link to={`https://shop.pricefinder.in/product/${values.slug}`} target="_blank">
                                  {values.featured_image_url ? (
                                    <img
                                      src={values.featured_image_url}
                                      className="card-img-top product-image"
                                      alt={values.name}
                                    />
                                  ) : (
                                    <img
                                      src={defaultImage}
                                      className="card-img-top product-image"
                                      alt={values.name}
                                    />
                                  )}
                                </Link>
                              ) : (
                                <>
                                  {values.featured_image_url ? (
                                    <img
                                      src={values.featured_image_url}
                                      className="card-img-top product-image"
                                      alt={values.name}
                                    />
                                  ) : (
                                    <img
                                      src={defaultImage}
                                      className="card-img-top product-image"
                                      alt={values.name}
                                    />
                                  )}
                                </>
                              )}

                              <div className="card-body d-flex flex-column justify-content-between">

                                <h5 className="card-price">
                                  {values.regular_price !== "0" ?
                                    <span>₹{Number(values.regular_price).toFixed(2)}</span> : <p>RFQ</p>
                                  }
                                </h5>

                                <p className="card-text">
                                  {values.product_slug ? (<Link to={`https://shop.pricefinder.in/product/${values.slug}`} target="_blank"><span dangerouslySetInnerHTML={{ __html: values.product_title }}></span></Link>) : (<span dangerouslySetInnerHTML={{ __html: values.product_title }}></span>)}
                                  {" "}
                                  {(values.is_virtual && values.product_stock_quantity !== null) &&
                                    <span>
                                      - Available {values.product_stock_quantity}
                                    </span>
                                  }
                                </p>
                                {(((isVacation === "closed" || storeStatus === "closed") && !values.hyperlocal) || (isVacation !== "closed" && storeStatus !== "closed")) ? (
                                  values.product_out_of_stock ?
                                    <div className="outofstock">Out of Stock</div> :
                                    <div
                                      className={`addtocard ${productQuantity[values.product_id] > 0 ? "active" : ""
                                        }`}
                                    >
                                      {productQuantity[values.product_id] > 0 && (
                                        <div
                                          className="sub-btn"
                                          onClick={() => decreaseQuantity(values.product_id)}
                                        >
                                          -
                                        </div>
                                      )}
                                      {productQuantity[values.product_id] > 0 && (
                                        <div className="quantity-btn">
                                          {productQuantity[values.product_id]}
                                        </div>
                                      )}
                                      <div
                                        className="cartadd"
                                        onClick={() => increaseQuantity(values.product_id)}
                                        style={{
                                          display:
                                            productQuantity[values.product_id] > 0
                                              ? "none"
                                              : "block",
                                        }}
                                      >
                                        Add
                                      </div>
                                      <div
                                        className="add-sym"
                                        onClick={() => increaseQuantity(values.product_id)}
                                      >
                                        +
                                      </div>
                                    </div>
                                ) : (<div className="outofstock">Not Avaiable</div>)}
                              </div>
                            </div>
                          </div>
                        ))}</> : <h4 style={{ textAlign: "center", marginTop: '2rem' }}>Sorry! Currently, there are no slots available for this category.</h4>}
                      </div>)}
                  </div>

                }

              </div>
              <div className="navigation_button">
                <div className="page_nav">
                  {number > 1 && pages > 1 ? (
                    <button
                      className="btn btn-info button_previous"
                      onClick={handlePreviousPage}
                    >
                      Previous
                    </button>
                  ) : (
                    <button className="btn btn-info button_previous disabled">
                      Previous
                    </button>
                  )}
                  <span className="page__number">
                    {number} of {pages}
                  </span>
                  {pages > number ? (
                    <button
                      className="btn btn-info button_next"
                      onClick={handleNextPage}
                    >
                      Next
                    </button>
                  ) : (
                    <button className="btn btn-info button_next disabled">
                      Next
                    </button>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="cart-design" onClick={handleCartDesignClick}>
        <Cart
          itemCount={itemCount}
          totalPrice={totalPrice}
          cartItems={cartItems.filter(item => item.id !== undefined)}
          productQuantity={productQuantity}
        />
      </div>
      <CartWindow
        isOpen={isCartPopupOpen}
        onClose={handleCloseCartPopup}
        itemCount={itemCount}
        totalPrice={totalPrice}
        cartItems={cartItems}
        productQuantity={productQuantity}
        decreaseQuantity={decreaseQuantity}
        increaseQuantity={increaseQuantity}
        clearCart={clearCart}
      />
      <Footer />
    </div>
  );
};

export default Products;
