import React from 'react'
import Footer from '../Footer/Footer'

const About = (about) => {
  const offersDetails = about.about;
  return (
    <div>
      <h2 className='section_title'>About</h2>
      <p className='about_section'> <span dangerouslySetInnerHTML={{ __html: offersDetails.post_content }} /></p>
      <Footer />
    </div>
  )
}

export default About