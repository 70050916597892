import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { IoMdClose } from "react-icons/io";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import Footer from '../Footer/Footer';

const Gallery = (props) => {
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState(null);
  const images = props.gallery.directory_photos_urls;

  const openFullScreen = (index) => {
    setFullScreenImageIndex(index);
    document.body.style.overflowY = 'hidden';
  };

  const closeFullScreen = () => {
    setFullScreenImageIndex(null);
    document.body.style.overflowY = 'scroll';
  };

  const previousImage = () => {
    let newIndex;
    if (fullScreenImageIndex === 0) {
      newIndex = images.length - 1; 
    } else {
      newIndex = fullScreenImageIndex - 1;
    }
    setFullScreenImageIndex(newIndex);
  };

  const nextImage = () => {
    let newIndex;
    if (fullScreenImageIndex === images.length - 1) {
      newIndex = 0; 
    } else {
      newIndex = fullScreenImageIndex + 1;
    }
    setFullScreenImageIndex(newIndex);
  };

  return (
    <>
      <h2 className='section_title'>Gallery</h2>
      {fullScreenImageIndex !== null && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <button
            onClick={closeFullScreen}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 99999,
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              color: '#fff',
              fontSize: '24px',
            }}
          >
            <IoMdClose />
          </button>
          <button
            onClick={previousImage}
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: '10px',
              zIndex: 99999,
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              color: '#fff',
              fontSize: '24px'
            }}
          >
            <GrLinkPrevious />
          </button>

          <img
            src={images[fullScreenImageIndex]}
            alt=""
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              objectFit: 'contain',
            }}
            onClick={(e) => e.stopPropagation()}
          />
          <button
            onClick={nextImage}
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: '10px',
              zIndex: 99999,
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              color: '#fff',
              fontSize: '24px',
            }}
          >
            <GrLinkNext />
          </button>
        </div>
      )}
      <div className='gallery-section'>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
        >
          <Masonry gutter="20px">
            {images.map((image, index) => (
              <img
                src={image}
                alt=""
                key={index}
                style={{
                  width: '100%',
                  display: 'block',
                  cursor: 'pointer',
                }}
                onClick={() => openFullScreen(index)}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
