import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer'
import './notFound.css'

const NotFound = () => {
  return (
    <>
    <div className="not-found-container">
      <h1 className="not-found-title">Page Not Found</h1>
      <p className="not-found-message">Sorry, the page you are looking for does not exist.</p>
      <Link to="/" className="not-found-link">Go to Home Page</Link>
    </div>
      <Footer />
    </>
  );
};

export default NotFound;
