import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { GoVerified } from "react-icons/go";
import { CgClose } from "react-icons/cg";
import { VscCheckAll } from "react-icons/vsc";
import DefaultImage from "../../assets/default.png";
import Footer from "../Footer/Footer";
import HomeCard from "../HomeCard/HomeCard";
import "./HomePage.css";

function Home() {

  const [productValues, setProductValues] = useState([]);
  const [pages, setPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [, setIsSuggestionSelected] = useState(false);
  const [number, setnumber] = useState(1);
  const [productSuggestionShow, setProductSuggestionShow] = useState([]);
  const [selectedProductSuggestion, setSelectedProductSuggestion] =
    useState(-1);
  const [citySuggestionShow, setCitySuggestionShow] = useState([]);
  const [selectedCitySuggestion, setSelectedCitySuggestion] = useState(-1);
  const [previousCitySuggestions, setPreviousCitySuggestions] = useState([]);
  const [previousCity, setPreviousCity] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [inquiryPopup, setinquiryPopup] = useState();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [, setCityInputClicked] = useState(false);

  // Refs to handle click outside suggestions
  const productSuggestionRef = useRef(null);
  const citySuggestionRef = useRef(null);
  const productShowRef = useRef(null);

  // Function to fetch product suggestions from API
  const fetchProductSuggestions = async (inputText) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      let url = "";
      if (inputText) {
        url = `https://shop.pricefinder.in/wp-json/custom/v1/directory-tag-suggestions?input=pro-${inputText}`;
      }
      else {
        url = `https://shop.pricefinder.in/wp-json/custom/v1/directory-tag-suggestions`;
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const productDetails = response.data;
      setProductSuggestionShow(productDetails);
      setSelectedProductSuggestion(-1);
    } catch (error) {
      console.error("Error fetching product suggestions:", error);
    } finally {
    }
  };

  // Function to fetch city suggestions from API
  const fetchCitySuggestions = async (citysug) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom-dir/v1/kb-dir-tag-suggestions?input=${citysug}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });
      const cityDetails = response.data;
      setPreviousCitySuggestions(cityDetails);
      setCitySuggestionShow(cityDetails);
      setSelectedCitySuggestion(-1);
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    }
  };

  // Function to handle click on product suggestion
  const handleProductSuggestionClick = (sug) => {
    setSearchText(sug);
    fetchProductDetails(sug);
    setIsSuggestionSelected(true);
    setnumber(1);
    setProductSuggestionShow([]);
    setSelectedProductSuggestion(-1);
    fetchCitySuggestions(sug);
    setCityInputClicked(false);
  };

  // Function to handle click on city suggestion
  const handleCitySuggestionClick = (sug) => {
    setSearchCity(sug);
    if (searchText !== "") {
      fetchProductDetailsByCity(searchText, sug);
    }
    setIsSuggestionSelected(false);
    setCityInputClicked(true);
    setCitySuggestionShow([]);
  };

  // Function to handle keyboard navigation within product suggestions
  const handleProductSuggestionNavigation = (e) => {
    const suggestions = productSuggestionShow;
    const lastIndex = suggestions.length - 1;

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setSelectedProductSuggestion((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : lastIndex
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedProductSuggestion((prevIndex) =>
          prevIndex < lastIndex ? prevIndex + 1 : 0
        );
        break;
      case "Enter":
        e.preventDefault();
        if (selectedProductSuggestion !== -1) {
          const selectedSuggestionText = suggestions[selectedProductSuggestion];
          handleProductSuggestionClick(selectedSuggestionText);
        }
        break;
      default:
        break;
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    // Collect form data
    const formData = new FormData(event.target);

    // Create the jsonObject based on the desired format
    const jsonObject = {
      subject: `${formData.get("subject")}`,
      name: `${formData.get("name")}`,
      phone: `${formData.get("phone")}`,
      email: `${formData.get("email")}`,
      description: `${formData.get("description")}`,
    };
    fetch(
      "https://shop.pricefinder.in/wp-json/custom/v1/submit-freshdesk-ticket",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonObject),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.error(data.message);
        setLoading(false);
        setFormLoading(false);
        // setSelectedProduct(null);
        // setinquiryPopup(false);
        // alert("Thank you for your inquiry! We will get back to you soon.");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setLoading(false);
      });
  }

  // Function to handle keyboard navigation within city suggestions
  const handleCitySuggestionNavigation = (e) => {
    const suggestions = citySuggestionShow;
    const lastIndex = suggestions.length - 1;

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setSelectedCitySuggestion((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : lastIndex
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedCitySuggestion((prevIndex) =>
          prevIndex < lastIndex ? prevIndex + 1 : 0
        );
        break;
      case "Enter":
        e.preventDefault();
        if (selectedCitySuggestion !== -1) {
          const selectedSuggestionText = suggestions[selectedCitySuggestion];
          handleCitySuggestionClick(selectedSuggestionText);
        }
        break;
      default:
        break;
    }
  };

  // Function to fetch product details from API based on search text
  const fetchProductDetails = async (sug) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom-dir/v1/vendor-products-by-tags?tags[]=${sug}&page=${number}&per_page=10`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const productDetails = response.data.custom_posts
      if (response.data.total_pages === 0) {
        setPages(1)
      }
      else {
        setPages(response.data.total_pages);
      }
      setProductValues(productDetails);
      // setCurrentPage(productDetails.current_page);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  // Function to handle next page button click
  const handleNextPage = () => {
    setnumber(number + 1);
    productShowRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Function to handle previous page button click
  const handlePreviousPage = () => {
    setnumber(number - 1);
    productShowRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Function to fetch product details based on city and product search text
  const fetchProductDetailsByCity = async (searchText, sug) => {
    setPages(0);
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      const cityTag = `city-${sug}`;
      let url = '';
      if (searchText.includes('pro-')) {
        url = `https://shop.pricefinder.in/wp-json/custom-dir/v1/products-by-tags?pro-tag=${searchText}&city-tag=${cityTag}&page=${number}&per_page=10`;
      } else {
        url = `https://shop.pricefinder.in/wp-json/custom-dir/v1/products-by-tags?page=${number}&category=${searchText}&city-tag=${cityTag}&per_page=10`;
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const productDetails = response.data.custom_posts;
      setPages(response.data.total_pages);
      setProductValues(productDetails);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };


  // Function to handle the last updated date
  const handleDate = (dateStr) => {
    const currentDate = new Date();
    const [day, month, year] = dateStr.split('-');
    const givenDate = new Date(`${year}-${month}-${day}`);
    const timeDifference = currentDate - givenDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference === 0) {
      return `Today`
    }
    else if (daysDifference === 1) {
      return `${daysDifference} day ago`

    }
    return `${daysDifference} days ago`
  };

  const handleInquiry = (vendor) => {
    setSelectedProduct(vendor);
    setinquiryPopup(true);
  };

  if (inquiryPopup) {
    document.body.classList.add('active-enquiry')
  }
  else {
    document.body.classList.remove('active-enquiry')
  }

  const handleCloseClick = () => {
    setSelectedProduct(null);
    setinquiryPopup(false);
    setFormLoading(true);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("inquiry_overlay")) {
      handleCloseClick();
    }
  };

  const isStoreOpen = (openingHours, vacationDates) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // Returns 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const currentTimeInSeconds = currentDate.getHours() * 3600 + currentDate.getMinutes() * 60 + currentDate.getSeconds();

    // Convert vacation dates from UNIX timestamp to Date objects
    const vacationDateObjects = vacationDates.map(timestamp => new Date(timestamp * 1000));

    // Check if today's date is in the vacation dates array
    const isTodayVacation = vacationDateObjects.some(date => date.toDateString() === currentDate.toDateString());

    // If today is a vacation day, store is closed
    if (isTodayVacation) {
      return false;
    }

    for (const hour of openingHours) {
      if (hour.day === currentDay) {
        if (currentTimeInSeconds >= hour.start && currentTimeInSeconds <= hour.end) {
          return true; // Store is open
        }
      }
    }
    return false; // Store is closed
  };

  // Effect to handle click outside suggestions and close them
  useEffect(() => {
    // Function to handle outside click
    const handleOutsideClick = (event) => {
      if (
        // Check if the click happened outside the second input box
        (!citySuggestionRef.current ||
          !citySuggestionRef.current.contains(event.target)) &&
        // Check if the click happened outside the first input box
        (!productSuggestionRef.current ||
          !productSuggestionRef.current.contains(event.target)) &&
        // Check if the click happened outside the city input box
        (!document.getElementById("city-search-input") ||
          event.target !== document.getElementById("city-search-input"))
      ) {
        // Reset the flag when the click is outside both input boxes
        setCityInputClicked(false);
        setIsSuggestionSelected(false);
        setProductSuggestionShow([]);
        setCitySuggestionShow([]);
      }
    };

    // Add event listener for outside click
    document.addEventListener("click", handleOutsideClick);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Fetch product details on initial mount and whenever 'number' changes
  useEffect(() => {
    if (searchCity) {
      fetchProductDetailsByCity(searchText, searchCity);
    } else {
      fetchProductDetails(searchText);
    }
  }, [number]);

  useEffect(() => {
    setSearchCity("")
  }, [searchText])


  useEffect(() => {
    if (searchCity !== previousCity && searchCity !== "") {
      setnumber(1);
    }
    setPreviousCity(searchCity);
  }, [searchCity]);

  return (
    <>
      <section ref={productShowRef} id="main_home">
        <div className="home">
          <div className="home_content">
            <div>
              <h1>Discover Prices, Locate Stores</h1>
            </div>
            <div>
              <p style={{ paddingBottom: "10px" }}>
                Inquire Effortlessly
              </p>
            </div>

            <div className="home_search">
              <div className="search_category">
                <input
                  type="search"
                  placeholder="Search product...."
                  list="productList"
                  value={searchText.startsWith('pro-') ? searchText.substring(4) : searchText}
                  id="search-input"
                  autoComplete="off"
                  onChange={(e) => {
                    const inputText = e.target.value.toLowerCase();
                    setSearchCity("");
                    setSearchText(inputText);
                    fetchProductSuggestions(inputText);
                    setIsSuggestionSelected(false);
                  }}
                  onKeyDown={handleProductSuggestionNavigation}
                  onClick={() => fetchProductSuggestions()}
                />

                <ul className="suggestions" ref={productSuggestionRef}>
                  {productSuggestionShow.map((sug, index) => {
                    const isSelected = index === selectedProductSuggestion;
                    return (
                      <li
                        onClick={() => handleProductSuggestionClick(sug.name ? sug.name : `pro-${sug}`)}
                        key={sug.name || sug}
                        className={isSelected ? "selected" : ""}
                      >
                        {sug.name || sug}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="search_city">
                <input
                  type="search"
                  placeholder="Search city...."
                  list="cityList"
                  value={searchCity}
                  id="city-search-input"
                  autoComplete="off"
                  onChange={(e) => {
                    const inputText = e.target.value.toLowerCase();
                    setSearchCity(inputText);
                    const filteredSuggestions = previousCitySuggestions.filter(
                      (suggestion) =>
                        suggestion.toLowerCase().startsWith(inputText)
                    );
                    setCitySuggestionShow(filteredSuggestions);
                    setIsSuggestionSelected(false);
                  }}
                  onClick={() => {
                    setCitySuggestionShow(previousCitySuggestions);
                    setIsSuggestionSelected(false);
                  }}
                  onKeyDown={handleCitySuggestionNavigation}
                  disabled={previousCitySuggestions.length < 1 || searchText === ""}
                />
                <ul className="suggestions" ref={citySuggestionRef}>
                  {citySuggestionShow.map((sug, index) => {
                    const isSelected = index === selectedCitySuggestion;
                    return (
                      <li
                        onClick={() => handleCitySuggestionClick(sug)}
                        key={sug}
                        className={isSelected ? "selected" : ""}
                      >
                        {sug}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="search_button">
                <span>
                  <svg
                    width="25"
                    height="25"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"></path>
                    <path d="m21 21-6-6"></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Table showing product details */}
        {productValues.length > 0 && (<>
          <div className="product_show_1">
            <div className="product-show">
              <div className="table_product">
                <div className="row table_for_bigscreen">
                  {/* <div className="col-lg-12 col-sm-12"></div> */}
                  <div className="col-lg-12 col-sm-12">
                    <div className="mt-5">
                      <table className="table table-striped table-hover table-fixed tableshow">
                        <thead className="thead table_heading">
                          <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Last Updated</th>
                            {productValues.some(
                              (product) => (product.author_name && !product.is_virtual)) && <th>Availability</th>}
                            {productValues.some(
                              (product) => product.author_name) && <th>Vendor Name</th>}
                            {productValues.some(
                              (product) => product.author_name) && <th>Status</th>}
                            {productValues.some(
                              (product) => product.author_name) && <th>Visit</th>}
                            <th>Inquiry</th>
                          </tr>
                        </thead>
                        <tbody className="tbody">
                          {/* Map through productValues array to render product details */}
                          {productValues.map((product, index) => (
                            <tr key={index} className="tr_tables">
                              <td>
                                <span className="img-container">
                                  {product.featured_image_url ? (
                                    <img
                                      src={product.featured_image_url}
                                      alt="Product"
                                      className="img"
                                      loading="lazy"
                                    />
                                  ) : (
                                    <img
                                      src={DefaultImage}
                                      alt="Default Product"
                                      className="img"
                                    />
                                  )}
                                  {/* Enlarged image */}
                                  <span className="img-enlarged">
                                    {product.featured_image_url ? (
                                      <img src={product.featured_image_url} alt="Product" />
                                    ) : (
                                      <>
                                        {product.featured_image_url > 0 ? (
                                          <img
                                            src={product.featured_image_url[0].src}
                                            alt="Default Product"
                                          />
                                        ) : (
                                          <img
                                            src={DefaultImage}
                                            alt="Default Product"
                                          />
                                        )}
                                      </>
                                    )}
                                  </span>
                                </span>
                              </td>
                              <td className="productName">
                                {product.product_slug ? <Link to={`https://shop.pricefinder.in/product/${product.product_slug}`} target="_blank">{product.post_title}</Link> : <span>{product.post_title}</span>}
                                {" "}
                                {product.verified === "yes" && (
                                  <span title="Verified">
                                    <GoVerified className="verified" />
                                  </span>
                                )}
                              </td>
                              <td>
                                {product.regular_price.value ? <span className="price">₹{Number(product.regular_price.value).toFixed(2)}</span>
                                  : <span className="price">₹{Number(product.regular_price).toFixed(2)}</span>}
                              </td>
                              <td className="lastUpdated">{handleDate(product.last_updated_date)}</td>
                              {(product.author_name &&
                                product.author_name !== "PriceFinder" && !product.is_virtual) && (
                                  <td style={{ textAlign: "center" }}>
                                    <span>
                                      {product.product_stock_quantity === null ? "Yes" :
                                        (product.product_stock_quantity > 0 ? product.product_stock_quantity : "Out of Stock")
                                      }
                                    </span>
                                  </td>
                                )}
                              {product.author_name &&
                                product.author_name !== "PriceFinder" && (
                                  <td className="authorName">
                                    {" "}
                                    <span>{product.author_name}</span>

                                  </td>
                                )}
                              {(product.directory_shop_open || product.directory_shop_close || product.directory_opening_hours) &&
                                <td>
                                  <span className="store_Status">
                                    {(!product.directory_shop_open && !product.directory_shop_close) ? (
                                      isStoreOpen(product.directory_opening_hours, product.directory_vacation) ?
                                        <span className="btn btn-success">Open</span> : <span className="btn btn-danger">Closed</span>
                                    ) : (
                                      product.directory_shop_open ?
                                        <span className="btn btn-success">Open</span> : <span className="btn btn-danger">Closed</span>
                                    )}
                                  </span>
                                </td>
                              }
                              {product.author_name &&
                                product.author_name !== "PriceFinder" && (
                                  <td>
                                    {product.slug &&
                                      (
                                        product.is_virtual ? (
                                          <Link
                                            className="vendor_link"
                                            to={`/store/${product.author_id}`}
                                            target="_blank"
                                          >
                                            <button className="btn btn-success">
                                              Book Now
                                            </button>
                                          </Link>
                                        ) : (
                                          <Link
                                            className="vendor_link"
                                            to={`/store/${product.author_id}`}
                                            target="_blank"
                                          >
                                            <button className="btn btn-success">
                                              Visit Store
                                            </button>
                                          </Link>
                                        )
                                      )}
                                  </td>
                                )}

                              <td>
                                <button
                                  className="btn btn-success"
                                  onClick={() => handleInquiry(product)}
                                >
                                  Inquiry
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row table_for_responsive">
                <div className="col-lg-12 col-sm-12"></div>
                <div className="col-lg-11 col-sm-12">
                  <div className="mt-5">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead className="thead-light">
                          <tr>
                            <th>Image</th>
                            <th>Details</th> {/* Modified column heading */}
                          </tr>
                        </thead>
                        <tbody className="tbody">
                          {productValues.map((names, index) => (
                            <tr key={index} className="tr_tables">
                              <td>
                                <div className="img-container">
                                  {names.featured_image_url ? (
                                    <img
                                      src={names.featured_image_url}
                                      alt="Product"
                                      className="img"
                                    />
                                  ) : (
                                    <>
                                      {names.images > 0 ? (
                                        <img
                                          src={names.images[0].src}
                                          alt="Default Product"
                                          className="img"
                                        />
                                      ) : (
                                        <img
                                          src={DefaultImage}
                                          alt="Default Product"
                                          className="img"
                                        />
                                      )}
                                    </>
                                  )}
                                  <div className="img-enlarged">
                                    {names.featured_image_url ? (
                                      <img src={names.featured_image_url} alt="Product" />
                                    ) : (
                                      <>
                                        {names.images > 0 ? (
                                          <img
                                            src={names.images[0].src}
                                            alt="Default Product"
                                          />
                                        ) : (
                                          <img
                                            src={DefaultImage}
                                            alt="Default Product"
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <strong>Name:</strong> {names.product_slug ? <Link to={`https://shop.pricefinder.in/product/${names.product_slug}`} target="_blank">{names.post_title}</Link> : <span>{names.post_title}</span>} {" "}
                                  {names.verified === "yes" && (
                                    <span title="Verified">
                                      <GoVerified className="verified" />
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <strong>Price:</strong>{" "}
                                  {names.regular_price.value ? <span className="price">₹{Number(names.regular_price.value).toFixed(2)}</span>
                                    : <span className="price">₹{Number(names.regular_price).toFixed(2)}</span>}
                                  {" "}
                                  {names.regular_price !== "" &&
                                    names.regular_price < names.sale_price ? (
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      ₹{Number(names.regular_price).toFixed(2)}
                                    </span>
                                  ) : null}

                                  <div>
                                    <strong>Last Updated: </strong>
                                    {handleDate(names.last_updated_date)}
                                  </div>
                                  {(names.author_name &&
                                    names.author_name !== "PriceFinder" && !names.is_virtual) && (
                                      <div>
                                        <strong>Availability: </strong><span>
                                          {names.product_stock_quantity === null ? "Yes" :
                                            (names.product_stock_quantity > 0 ? names.product_stock_quantity : "Out of Stock")
                                          }
                                        </span>
                                      </div>
                                    )}
                                  <p>{productValues.some(
                                    (product) => product.author_name && product.author_name !== ""
                                  ) && <span><strong>Vendor Name</strong>  {names.author_name}</span>}
                                    <div><strong>Status:</strong>{" "}
                                      {(names.directory_shop_open || names.directory_shop_close || names.directory_opening_hours) &&
                                        <span className="store_Status">
                                          {(!names.directory_shop_open && !names.directory_shop_close) ? (
                                            isStoreOpen(names.directory_opening_hours, names.directory_vacation) ?
                                              <span className="btn btn-success">Open</span> : <span className="btn btn-danger">Closed</span>
                                          ) : (
                                            names.directory_shop_open ?
                                              <span className="btn btn-success">Open</span> : <span className="btn btn-danger">Closed</span>
                                          )}
                                        </span>
                                      }
                                    </div>
                                  </p>
                                </div>

                                <div className="button_for_responsive">
                                  <div className="mt-2">
                                    {/* <Link
                                    className=""
                                    to={names.permalink}
                                    target="_blank"
                                  >
                                    <button className="btn btn-success">
                                      View Prod
                                    </button>
                                  </Link> */}
                                  </div>
                                  <div>
                                    {names.author_name &&
                                      names.author_name !== "PriceFinder" && (
                                        names.is_virtual ? (
                                          <Link
                                            className="vendor_link"
                                            to={`/store/${names.author_id}`}
                                            target="_blank"
                                          >
                                            <button className="btn btn-success">
                                              Book Now
                                            </button>
                                          </Link>
                                        ) : (
                                          <Link
                                            className="vendor_link"
                                            to={`/store/${names.author_id}`}
                                            target="_blank"
                                          >
                                            <button className="btn btn-success">
                                              Visit Store
                                            </button>
                                          </Link>
                                        )
                                      )}
                                  </div>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleInquiry(names)}
                                  >
                                    Inquiry
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navigation_button">
            <div className="page_nav">
              {number > 1 && pages > 1 ? (
                <button
                  className="btn btn-success button_previous"
                  onClick={handlePreviousPage}
                >
                  Previous
                </button>
              ) : (
                <button className="btn btn-success button_previous disabled">
                  Previous
                </button>
              )}
              <span className="page__number">
                {number} of {pages}
              </span>
              {pages > number ? (
                <button
                  className="btn btn-success button_next"
                  onClick={handleNextPage}
                >
                  Next
                </button>
              ) : (
                <button className="btn btn-success button_next disabled">
                  Next
                </button>
              )}
            </div>
          </div>
        </>)}
        <HomeCard />
        {inquiryPopup && selectedProduct && (
          <div className="inquiry_overlay" onClick={handleOverlayClick}>
            <div className="inquiry_popup">
              <div className="popup__header">
                <div className="popup__product">
                  <h3>{selectedProduct.post_title}</h3>
                  {selectedProduct.regular_price.value &&
                    <h3>₹{selectedProduct.regular_price.value}</h3>
                  }
                </div>
                <button className="close_button" onClick={handleCloseClick}>
                  <CgClose />
                </button>
                {selectedProduct.featured_image_url !== "" && <div className="product__logo">
                  <img src={selectedProduct.featured_image_url} alt="" />
                </div>}

              </div>

              {formLoading && (
                <div className="heading_close">
                  <div className="heading_popup">
                    <h4>Ask a Question</h4>
                  </div>
                </div>
              )}
              {formLoading ? (
                <form onSubmit={handleSubmit} method="POST" className="form">
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name (required)"
                    required
                    autoFocus
                  />
                  <input
                    type="hidden"
                    name="subject"
                    value={selectedProduct.author_name ? `${selectedProduct.author_name} - ${selectedProduct.post_title}` : `${selectedProduct.post_title}`}
                  />
                  <div className="input__email">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email (required)"
                      inputMode="email"
                      required
                    />
                    <input
                      type="phone"
                      name="phone"
                      placeholder="Phone Number (required)"
                      required
                      inputMode="tel"
                    />
                  </div>
                  <textarea
                    type="text"
                    className="textarea"
                    name="description"
                    required
                    placeholder="Your Message (required)"
                  ></textarea>
                  <div className="h-captcha" data-captcha="true"></div>
                  <button type="submit" disabled={loading}>
                    {loading ? "SENDING..." : "SEND"}
                  </button>
                </form>
              ) : (
                <div className="thankyou__message">
                  <div className="icon">
                    <VscCheckAll />
                  </div>
                  <p>
                    Thank you for your inquiry! We will get back to you soon.
                  </p>
                  <button
                    className="btn btn-success center"
                    onClick={handleCloseClick}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <Footer />
      </section>
    </>
  );
}

export default Home;
