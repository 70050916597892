import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import data from './HomeCardData.js';
import './HomeCard.css';

const HomeCard = () => {
    return (
        <>
            <Container maxWidth='lg'>
                <Typography variant='h4' style={{ marginTop: '50px', textAlign: 'center' }}>
                Why Choose PriceFinder.in?
                </Typography>
                <Grid container spacing={5} justifyContent="center" alignItems="stretch" style={{ marginTop: '20px' }}>
                    {data.map((result, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card sx={{ display: 'flex', flexDirection: 'column', height: '92%' }} style={{ marginBottom: '30px' }}>
                                <CardActionArea>
                                    <CardMedia
                                        className="card-image"
                                        component="img"
                                        height="160"
                                        image={result.img}
                                        alt={result.title}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {result.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {result.des}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
}

export default HomeCard;
