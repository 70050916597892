import React from 'react'
import { Link } from "react-router-dom";
import Footer from '../Footer/Footer';
import './OfferDetailsPage.css'


const Shop = (store) => {
  const storeDetails = store.store;
  return (
    <>
      <div className='container'>
        <h2 className='section_title'>Offered by</h2>
        <h3 className='offered_by'>
          <Link to={`/${storeDetails.author.id}`} state={{ authorId: storeDetails.author.id }}>
            <span dangerouslySetInnerHTML={{ __html: storeDetails.author.display_name }} />
          </Link>
        </h3>
      </div>
      <Footer />
    </>
  )
}

export default Shop