import React, { useState } from 'react'
import { Typography, Card, CardContent, Grid, TextField, Button } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import Footer from '../Footer/Footer'

const Contact = () => {

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);

    // Create the jsonObject based on the desired format
    const jsonObject = {
      fname: `${formData.get("fname")}`,
      lname: `${formData.get("lname")}`,
      email: `${formData.get("email")}`,
      phone: `${formData.get("phone")}`,
      subject: `${formData.get("subject")}`,
      message: `${formData.get("message")}`,
    };

    fetch(
      "https://shop.pricefinder.in/wp-json/custom/v1/submit-freshdesk-ticket",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonObject),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.error(data.message);
        setShowModal(true)
        setFormData({
          fname: '',
          lname: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        });

      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });

  }

  return (
    <>
      <div className='container'>
        <h2 className='section_title'>Contact Us</h2>
        <Card style={{ maxWidth: 500, margin: "1rem auto 2rem", padding: "10px 5px" }}>
          <CardContent>
            <Typography variant="body2" component="p" color="textPrimary" style={{ marginBottom: "20px" }}>Fill up the form and our team will get back to you within 24 hours.</Typography>
            <form onSubmit={handleSubmit} method="POST">
              <Grid container spacing={1}>
                <Grid xs={12} sm={6} item>
                  <TextField label="First Name" name="fname" placeholder='Enter first name' variant='outlined' value={formData.fname} onChange={(e) => handleInputChange('fname', e.target.value)} autoFocus fullWidth required />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField label="Last Name" name="lname" placeholder='Enter last name' variant='outlined' value={formData.lname} onChange={(e) => handleInputChange('lname', e.target.value)} fullWidth required />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField type='email' name="email" inputMode="email" label="Email" placeholder='Enter email address' variant='outlined' value={formData.email} onChange={(e) => handleInputChange('email', e.target.value)} fullWidth required />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField type='number' name='phone' inputMode="tel" label="Phone" placeholder='Enter phone number' variant='outlined' value={formData.phone} onChange={(e) => handleInputChange('phone', e.target.value)} fullWidth required />
                </Grid>
                <Grid xs={12} item>
                  <TextField label="Subject" name='subject' placeholder='Enter subject' variant='outlined' value={formData.subject} onChange={(e) => handleInputChange('subject', e.target.value)} fullWidth required />
                </Grid>
                <Grid xs={12} item>
                  <TextField label="Message" name='message' multiline rows={3} placeholder='Type your message here' variant='outlined' value={formData.message} onChange={(e) => handleInputChange('message', e.target.value)} fullWidth required />
                </Grid>
                <Grid xs={12} item>
                  <Button type="submit" variant="contained" color="primary" fullWidth >Submit</Button>
                </Grid>

              </Grid>
            </form>
          </CardContent>
        </Card>
        {showModal &&
          <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={handleCloseModal}
          // style={{maxWidth: "500px", textAlign: "center", margin: "auto"}}
          >
            <Modal.Body>
              <p>
                Thanks for reaching out to us! Your request has been recorded. We will contact you shortly.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseModal}>Close</Button>
            </Modal.Footer>
          </Modal>
        }
      </div>
      <Footer />
    </>

  )
}

export default Contact