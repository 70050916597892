import React from 'react';
import Skeleton from 'react-loading-skeleton';

const StoreSkeleton = ({ store }) => {
    return (
        Array(store).fill(0).map((item ,i ) =>
            <div className="store-skeleton store" key={i}>
                <div className="store_banner">
                    <Skeleton height={200} />
                    <div className="store_banner_content">
                        <div className="store_title">
                            <h3 className="store__title">
                                <Skeleton width={200} />
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="store_visit">
                    <div className="inquiry_btn">
                        <Skeleton width={100} />
                    </div>
                    <div className="visit">
                        <Skeleton width={100} />
                    </div>
                </div>
            </div>
        )
    );
};

export default StoreSkeleton;
