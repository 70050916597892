import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Logo from "../../assets/Logo.png";
import { FaXTwitter, FaSquareFacebook } from "react-icons/fa6";
import "./Footer.css";

export default function Footer() {

  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  return (
    <>
      <div className="footer">
        <div className="pf_footer section_padding">
          <div className="pf_footer-links">
            <div className="pf_footer-links-div">
              <a href="/"><img src={Logo} alt="Logo" /></a>
              <p className="about-us"><strong>PriceFinder.in</strong> assists in finding the prices of products and services, and facilitates customers in booking enquiries with shop owners. We serve as a bridge between customers and store owners.</p>
            </div>
            <div className="pf_footer-links-div">
              <h4>Reach Us</h4>
              <p>Karthikeyapuram, Madipakkam, Chennai.</p>
              <p><a href="tel:+918072541345"><span>(+91)-8072541345</span></a></p>
              <p><a href="mailto:pricefinder.in@gmail.com"><span>pricefinder.in@gmail.com</span></a></p>
              <p><a href="/"><span>pricefinder.in</span></a></p>
            </div>
            <div className="pf_footer-links-div links">
              <h4>Quick Links</h4>
              <p><span onClick={() => setShowPrivacy(true)}>Privacy Policy</span></p>
              <Modal
                show={showPrivacy}
                onHide={() => setShowPrivacy(false)}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Privacy Policy
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                  <p>
                    <strong>Information Collection:</strong> We collect customers' names, email addresses, phone numbers, and addresses when they are interested in the listing service for inquiry. This information may include, but is not limited to, IP addresses, browser details, and device information.
                    <br />
                    <strong>Usage of Information:</strong> The information collected from PriceFinder.in is used for internal purposes such as website analytics, content optimization, and marketing strategies. As a lead generation company, we act as a bridge between vendors and visitors, so we will send the inquiry details to respective vendors. We assure our customers that their details will only be shared with qualified leads whom PriceFinder.in believes the particular vendor is fit to serve. Additionally, there is an option for visitors or customers to send their inquiries to specific vendors. Apart from this, customers' details are not publicly published or sold.
                    <br />
                    <strong>Product Listings:</strong> PriceFinder.in lists product prices from various vendors, including ourselves. These prices are approximate and provided for informational purposes only. Visitors are responsible for further market analysis and decision-making. We do not validate each vendor's product listing prices, so it is the visitors' responsibility to research further on prices. Additionally, we display updated data when showing the prices to give awareness to our visitors of how old the data is. We encourage our vendors to update the prices regularly. We manage product prices in the name of Knowledge base search to provide information about product or service prices (which is the first search input box on the home page). We obtain these prices from various sources, so it is up to the visitors to verify the prices. We are not responsible for the accuracy of prices.
                    <br />
                    <strong>Shop page:</strong> We provide an option for visitors to make online inquiries easily to get rid of phone orders through the "Shop" tab of the vendors' page. This inquiry is directly sent to us, not to vendors as of now. Through our support system, we manage them, and then we will send the shop inquiry to the relevant shop owner. In this process, there is no payment gateway involved, nor is payment processed through our side. All payment is processed through vendors. We are not responsible for any loss for both vendors or visitors.
                    <br />
                    <strong>Cookies:</strong> We use cookies to personalize content, analyze website traffic, and improve user experience. Users have the option to disable cookies in their browser settings, although this may affect certain features of the website.
                    <br />
                    <strong>Third-Party Links:</strong> PriceFinder.in may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these external sites and encourage users to review their privacy policies.
                    <br />
                    <strong>Security:</strong> We employ industry-standard security measures to protect user information from unauthorized access, alteration, or disclosure.
                    <br />
                    <strong>Policy Updates:</strong> This privacy policy may be updated periodically to reflect changes in our practices or legal requirements. Users will be notified of any significant updates.
                    <br /><br />
                    By using <strong>PriceFinder.in</strong>, visitors and users agree to the terms outlined in this privacy policy.
                  </p>
                </Modal.Body>
              </Modal>
              <p><span onClick={() => setShowTerms(true)}>Terms & Conditions</span></p>
              <Modal
                show={showTerms}
                onHide={() => setShowTerms(false)}
                dialogClassName="modal-95w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Terms & Conditions
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                  <p>

                    <strong>Acceptance of Terms:</strong> By accessing or using PriceFinder.in, users agree to comply with these terms and conditions.
                    <br />
                    <strong>Use of Content:</strong> All content on PriceFinder.in, including text, images, and logos, is the property of PriceFinder.in and may not be reproduced or distributed without permission.
                    <br />
                    <strong>Product Listings:</strong> PriceFinder.in lists product prices from various vendors. While we strive to provide accurate information, prices are approximate and subject to change. Users are advised to verify prices with the respective vendors before making any purchase decisions.
                    <br />
                    <strong>User Conduct:</strong> Users are prohibited from engaging in any activity that may disrupt or interfere with the operation of PriceFinder.in. This includes but is not limited to hacking, spamming, or transmitting malware.
                    <br />
                    <strong>Disclaimer of Warranties:</strong> PriceFinder.in is provided on an "as is" and "as available" basis without warranties of any kind, either express or implied. We do not guarantee the accuracy, completeness, or reliability of the information provided.
                    <br />
                    <strong>Limitation of Liability:</strong> PriceFinder.in and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use of PriceFinder.in.
                    <br />
                    <strong>Changes to Terms:</strong> PriceFinder.in reserves the right to update or modify these terms and conditions at any time without prior notice. Users are responsible for reviewing these terms periodically for changes.
                    <br /><br />
                    By using <strong>PriceFinder.in</strong>, users agree to abide by these terms and conditions. If you do not agree with any part of these terms, you are advised not to use the website.
                  </p>
                </Modal.Body>
              </Modal>
            </div>
            <div className="pf_footer-links-div">
              <h4>Timings</h4>
              <div className="timings">
                <div className="timimgs_list">
                  <p>Monday - Friday</p>
                  <p>09:00 - 21:00</p>
                </div>
                <div className="timimgs_list">
                  <p>Saturday</p>
                  <p>09:00 - 21:00</p>
                </div>
                <div className="timimgs_list">
                  <p>Sunday</p>
                  <p>09:00 - 21:00</p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="pf_footer-below">
            <div className="pf_footer-below-copyright">
              <p>
                © 2024 – All Right reserved!
              </p>
            </div>
            <div className="pf_footer-below-links social_media">
              <a href="https://twitter.com/pricefinder" target="_blank"><p><FaXTwitter /></p></a>
              <a href="https://facebook.com/pricefinder" target="_blank"><p><FaSquareFacebook /></p></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
