import React, { useState, useEffect } from "react";
import "./nav.css";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/Logo.png";

function Navigation() {
  const [showMenu, setShowMenu] = useState(false);
  const [serverStatus, setServerStatus] = useState(true); // Assume server is initially up

  useEffect(() => {
    // Fetch server status when component mounts
    checkServerStatus();
  }, []);

  const checkServerStatus = async () => {
    try {
      const response = await fetch(
        "https://shop.pricefinder.in/wp-json/custom/v1/list-directory-listings"
      );
      if (!response.ok) {
        throw new Error("Server is down");
      }
      setServerStatus(true); // Server is up
    } catch (error) {
      setServerStatus(false); // Server is down
    }
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <>
      {/* Server down message */}
      {!serverStatus && (
        <div className="server-down-message">
          Server is currently down. Please try again in a few minutes. Sorry for the inconvenience.
        </div>
      )}

      <section id="main_nav">
        <div className="nav_content">
          <div className="nav_hamburger_design">
            <div className="logo">
              <NavLink to="/" style={{ textDecoration: "none" }}>
                <img src={Logo} alt="logo" />
              </NavLink>
            </div>
            {/* Hamburger icon on the right */}
            <div
              className={`hamburger-icon ${showMenu ? "active" : ""}`}
              onClick={() => setShowMenu(!showMenu)}
            >
              <div className="line line-1"></div>
              <div className="line line-2"></div>
              <div className="line line-3"></div>
            </div>
          </div>
          <div className="nav-others">
            <div className="nav_others_all_mobile">
              {showMenu && (
                <div className="nav_others_all_mobile show-menu">
                  <NavLink
                    to="/store/"
                    style={{ textDecoration: "none", color: "black" }}
                    onClick={closeMenu}
                  >
                    <div>Listings</div>
                  </NavLink>
                  <NavLink
                    to="/offer"
                    style={{ textDecoration: "none", color: "black" }}
                    onClick={closeMenu}
                  >
                    <div>Offer</div>
                  </NavLink>
                  <NavLink
                    to="https://shop.pricefinder.in/contact-us/"
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                    onClick={closeMenu}
                  >
                    <div>Contact</div>
                  </NavLink>
                  <NavLink
                    to="https://shop.pricefinder.in/my-account/"
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                    onClick={closeMenu}
                  >
                    <button className="btn btn-success button_navigation_1">
                      Become a seller
                    </button>
                  </NavLink>
                </div>
              )}
            </div>
            <div className="nav_others_all">
              <div className="nav-others-content">
                <NavLink
                  to="/store/"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div>Listings</div>
                </NavLink>
                <NavLink
                  to="/offer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div>Offer</div>
                </NavLink>
                <NavLink
                  to="https://shop.pricefinder.in/contact-us/"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  <div>Contact</div>
                </NavLink>
              </div>
              <div className="nav-others-button">
                <NavLink
                  to="https://shop.pricefinder.in/my-account/"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  <button className="btn btn-success button_navigation_1">
                    Become a seller
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Navigation;
