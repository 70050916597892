import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import Footer from '../Footer/Footer';

const Offer = (id) => {
    const [offer, setOffer] = useState([]);
    const navigate = useNavigate();  // Create navigate function

    const authorId = id.id;
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    const fetchOfferDetails = async () => {
        try {
            const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
            const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

            const url = `https://shop.pricefinder.in/wp-json/custom/v1/list-offers-by-author/${authorId}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
                },
            });

            const offerDetails = response.data.offers;
            setOffer(offerDetails);
        } catch (error) {
            console.error('Error fetching vendor details:', error);
        }
    };

    useEffect(() => {
        fetchOfferDetails();
    }, []);

    const handleCardClick = (postTitle, postId) => {
        navigate(`/offer/${postTitle}`, { state: { offId: postId } });
    };

    return (
        <>
            <Container maxWidth="lg" style={{ marginTop: '30px' }}>
                <Typography variant="h4" align="center">
                    Offers
                </Typography>
                <Grid container spacing={5} style={{ marginTop: '15px' }}>
                    {offer.map((result, index) => (
                        <Grid item xs={12} md={4} sm={4} key={index}>
                            <Card sx={{ maxWidth: 345 }} onClick={() => handleCardClick(result.post_title.toLowerCase().replace(/\s+/g, ''), result.post_id)}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={result.featured_image_url}
                                        alt={result.post_title}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {result.post_title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {truncateText(result.post_content, 150)}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footer />
        </>
    );
};

export default Offer;
