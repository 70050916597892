import React, { useState, useEffect, useRef } from "react";
import { CiLocationOn } from "react-icons/ci";
import { useLocation  } from "react-router-dom";
import axios from "axios";
import About from "../Vendor/About";
import Gallery from './Gallery'
import Shop from './Shop'
import './OfferDetailsPage.css'

const OfferDetailsPage = ({ offId }) => {
  const [showoffer, setShowoffer] = useState([]);
  const [activeSection, setActiveSection] = useState("About");

  const productShowRef = useRef(null);

  const location = useLocation();
  const offerId = location.state ? location.state.offId : offId;


  const fetchofferDetails = async (offerId) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      const url = `https://shop.pricefinder.in/wp-json/custom/v1/get-offer-details/${offerId}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });
      const offerDetails = response.data;
      setShowoffer(offerDetails);

    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    }
  };



  const handleSectionClick = (section) => {
    setActiveSection(section);
    // Scroll to the selected section
    if (productShowRef.current) {
      productShowRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
 
  useEffect(() => {
    fetchofferDetails(offerId);
  }, [offerId]);

  return (
    <div className="offer">
      <div className="offer__info">
        <div className="offer__banner">
          <h1 className="offer__name">{showoffer.post_title}</h1>
        </div>
      </div>
      <div className="offer_nav">
      <div className="section_nav">
        <span
          onClick={() => handleSectionClick("About")}
          className={activeSection === "About" ? "active" : ""}
        >
          About
        </span>
        <span
          onClick={() => handleSectionClick("Gallery")}
          className={activeSection === "Gallery" ? "active" : ""}
        >
          Gallery
        </span>
        <span
          onClick={() => handleSectionClick("Store")}
          className={activeSection === "Store" ? "active" : ""}
        >
          Offered by
        </span>
      </div>
      </div>
       <div>
            {activeSection === "About" && (
            <div className="about-section">
                <About about={showoffer} />
            </div>
            )}
        {activeSection === "Gallery" && (
          <div className="gallery-section">
            <Gallery gallery={showoffer}/>
          </div>
        )}
        {activeSection === "Store" && (
          <div className="reviews-section">
            <Shop store={showoffer} />
          </div>
        )}
      </div> 

    </div>
  );
};

export default OfferDetailsPage;
