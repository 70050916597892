import React, { useState } from "react";
import Home from "./components/HomePage/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Shop from "./components/Store/Store";
import Vendor from "./components/Vendor/Vendor";
import Navigation from "./components/Navigation/nav";
import Footer from "./components/Footer/Footer";
import Offer from "./components/Offer/Offer";
import OfferDetailsPage from "./components/OfferDetailsPage/OfferDetailsPage";
import NotFound from "./components/NotFound/NotFound";

function App() { 

    return (
    <>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/store/" element={<Shop />} />
          <Route path="/offer/" element={<Offer />} />
          <Route path="/store/:vendorId" element={<Vendor />} />
          <Route path="/offer/:offerName" element={<OfferDetailsPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;